import React, { useEffect, useState, Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Amplify, { Auth, Hub } from "aws-amplify";
import awsConfig from "./awsConfig";
import i18n from "./i18n";
import "./assets/css/bootstrap.min.css";
import "./App.css";
import { UserData, RestaurantData } from "./GoelysRestoTypes";

const PathAPI = "https://api.goelys.com/GoelysResto";

const Home = lazy(() => import("./Home"));
const RestaurantForClient = lazy(() => import("./RestaurantForClient"));
const User = lazy(() => import("./User"));
const Aape = lazy(() => import("./Aape"));

Amplify.configure({
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: awsConfig.cognito.IDENTITY_POOL_ID,

    // REQUIRED - Amazon Cognito Region
    region: awsConfig.cognito.REGION,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: awsConfig.cognito.USER_POOL_ID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: awsConfig.cognito.APP_CLIENT_ID,

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true,

    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      domain: ".goelys.com",
      // OPTIONAL - Cookie path
      path: "/",
      // OPTIONAL - Cookie expiration in days
      expires: 365,
      // OPTIONAL - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      secure: true,
    },

    // OPTIONAL - Hosted UI configuration
    oauth: {
      domain: "login.goelys.com",
      scope: ["email", "profile", "openid"],
      redirectSignIn: "https://goelys.com",
      redirectSignOut: "https://goelys.com",
      responseType: "token", // 'code' or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
});

const App = () => {
  const [user, setUser] = useState<any>(null);
  const [userData, setUserData] = useState<UserData | null>(null);
  const [restaurantData, setRestaurantData] = useState<RestaurantData | null>(
    null
  );

  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          console.log("Signed in");
          Auth.currentAuthenticatedUser()
            .then((user) => {
              setUser(user);
              console.log("User: ", user);
              fetch(
                PathAPI +
                  "/user/" +
                  user.signInUserSession.idToken.payload.email
              )
                .then((response) => response.json())
                .then((response) => {
                  response.userData &&
                    setUserData(response.userData as UserData);
                  response.restaurantData &&
                    setRestaurantData(
                      response.restaurantData as RestaurantData
                    );
                    console.log("User data: ", response.userData as UserData);
                    console.log("Restaurant data: ", response.restaurantData as RestaurantData);
                });
            })
            .catch(() => console.log("No user"));
          break;
        case "signOut":
          setUser(null);
          console.log("Signed out");
          break;
        case "configured":
          console.log("Configured");
          Auth.currentAuthenticatedUser()
            .then((user) => {
              setUser(user);
              console.log("User: ", user);
              fetch(
                PathAPI +
                  "/user/" +
                  user.signInUserSession.idToken.payload.email
              )
                .then((response) => response.json())
                .then((response) => {
                  response.userData &&
                    setUserData(response.userData as UserData);
                  response.restaurantData &&
                    setRestaurantData(
                      response.restaurantData as RestaurantData
                    );
                    console.log("User data: ", response.userData as UserData);
                    console.log("Restaurant data: ", response.restaurantData as RestaurantData);
                });
            })
            .catch(() => console.log("No user"));
          break;
      }
    });

    /*setUser({
      signInUserSession: {
        idToken: {
          payload: {
            email: "mathieu.requier@gmail.com",
          },
        },
      },
    });*/

    if (!user)
      Auth.currentAuthenticatedUser()
        .then((user) => {
          setUser(user);
          console.log("User: ", user);
          fetch(
            PathAPI + "/user/" + user.signInUserSession.idToken.payload.email
          )
            .then((response) => response.json())
            .then((response) => {
              response.userData && setUserData(response.userData as UserData);
              response.restaurantData &&
                setRestaurantData(response.restaurantData as RestaurantData);
              console.log("User data: ", response.userData as UserData);
              console.log("Restaurant data: ", response.restaurantData as RestaurantData);
            });
        })
        .catch(() => console.log("No user"));
  }, []);

  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route path="/restaurant">
            <RestaurantForClient user={user} />
          </Route>
          <Route path="/user">
            <User
              user={user}
              userData={userData}
              restaurantData={restaurantData}
            />
          </Route>
          <Route path="/aape">
            <Aape />
          </Route>
          <Route path="/">
            <Home user={user} />
          </Route>
        </Switch>
      </Suspense>
    </Router>
  );
};

export default App;
